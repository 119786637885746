.lexical__text-bold {
  font-weight: 600 !important;
}

.lexical__text-italic {
  font-style: italic !important;
}

.lexical__text-strikethrough {
  text-decoration: line-through !important;
}

.lexical__text-underline {
  text-decoration: underline !important;
}

.lexical__text-underlineStrikethrough {
  text-decoration: underline line-through !important;
}

.lexical__list-ol {
  padding: 0 !important;
  list-style-type: decimal !important;
}

.lexical__list-ul {
  padding: 0 !important;
  list-style-type: disc !important;
}

.lexical__listitem {
  margin: 0.5rem 3.5rem !important;
}

.lexical__link {
  text-decoration: underline !important;
  color: #3b82f6 !important;
}
