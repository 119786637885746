@import url('./fonts.css');
@import url('./variables.css');
@import url('./fieldArray.css');
@import url('./googleMapsStyles.css');
@import url('./lexical.css');
@import url('./swaggerUiCustom.css');

body,
html {
  overflow: auto;
  height: 100%;
  font-size: 62.5%;
  font-family: var(--font_family-base);
  line-height: 1.45;
  letter-spacing: 0.4px;
  color: var(--base-font_color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  --theme_color: var(--primary_color);
  --theme_color-lighten: var(--primary_color-lighten);
  --theme_color-darken: var(--primary_color-saturated);
  --theme_color_monochrome: var(--primary_color_monochrome);

  background: var(--body_bg);
  -webkit-print-color-adjust: exact !important;
}

body.warning {
  --theme_color: var(--warning_color);
  --theme_color-lighten: var(--warning_color-lighten);
  --theme_color-darken: var(--warning_color-saturated);
}

body.secondary {
  --theme_color: var(--secondary_color);
  --theme_color-lighten: var(--secondary_color-lighten);
  --theme_color-darken: var(--secondary_color-darken);
}

body.danger {
  --theme_color: var(--danger_color);
  --theme_color-lighten: var(--danger_color-lighten);
  --theme_color-darken: var(--danger_color-saturated);
}

body.success {
  --theme_color: var(--success_color);
  --theme_color-lighten: var(--success_color-lighten);
  --theme_color-darken: var(--success_color-saturated);
}

body.accent {
  --theme_color: var(--accent_color);
  --theme_color-lighten: var(--accent_color-lighten);
  --theme_color-darken: var(--accent_color-saturated);
}

body.secondary2 {
  --theme_color: var(--secondary2_color);
  --theme_color-lighten: var(--secondary2_color-lighten);
  --theme_color-darken: var(--secondary2_color-saturated);
}

body.pink {
  --theme_color: var(--color_base-brand-pink);
  --theme_color-lighten: var(--color_base-brand-lightpink);
  --theme_color-darken: var(--color_base-brand-pink-hover);
}

body.dragging {
  cursor: grabbing;
}

@media (max-width: 768px) {
  body,
  html {
    font-size: 48%;
  }

  body {
    -webkit-text-size-adjust: none;
  }
}

* {
  outline: none;
  touch-action: manipulation;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input:-webkit-autofill::first-line,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-size: 1.7rem;
  font-family: var(--font_family-accent);
}

b,
strong {
  font-weight: 600;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.link {
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.2rem;
  font-family: var(--font_family-mulish);
  letter-spacing: 0.1px;
  color: var(--primary_color);
}

.link:hover {
  text-decoration: underline;
}

.overflowHidden {
  overflow: hidden;
}

.popupLine b,
.popupLine strong {
  border-bottom: 2px solid var(--theme_color);
}

.popupLine .textBold {
  font-weight: 700;
}

.btn-reset {
  padding: 0;
  background: none;
  border: none;
}

@media (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
    border: 0.5px solid var(--input_placeholder_color);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--input_placeholder_color);
    border: 0.5px solid var(--input_placeholder_color);
    border-radius: 10px;
  }
}

.popupContainer {
  padding: 10px;
  background-color: #fff;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.breakWord {
  overflow-wrap: break-word;
}

.buttonReset {
  cursor: pointer;
  padding: 0;
  color: inherit;
  background-color: transparent;
  border: none;
}

.center {
  text-align: center;
}

.mapMarker {
  position: absolute;
  bottom: 0;
  left: -50px;
  display: inline-block;
  width: 100px;
  height: 30px !important;
  text-align: center;
  font-weight: bold;
  font-size: 18px !important;
}

.uppercase {
  text-transform: uppercase;
}

.LinesEllipsis {
  min-width: 100px;
}

.tdEmpty {
  opacity: 0;
  text-indent: -999999px;
  line-height: 24px;
}

.nowrap {
  white-space: nowrap;
}

.fw-420 {
  font-weight: 420;
}
