.wrapper {
  padding: 24px 16px;
}

.modalWrapper {
  z-index: 1305;
}

.modalContent {
  overflow: hidden;
  border-radius: 14px;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 68px;
  font-family: var(--font_family-accent);
  color: var(--gray_color-darken);
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  gap: 0.8rem;
  justify-content: flex-end;
  padding: 1.6rem;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  border-top: 1px solid #eee;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  backdrop-filter: blur(0.25rem);
}

.description {
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.02px;
}

.hintGif {
  margin-top: 14px;
  width: 100%;
  border-radius: 8px;
}

.checkbox {
  margin-top: 12px;
}
