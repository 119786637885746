/* -------------------------- common styles ------------------------------- */
.custom-main-jbd {
  --base-font_color: #363638;
  --jbd_header-height: 94px;
  --jbd_bg-color: #fff;
  --jbd-font_accented: #c5b181;

  color: var(--base-font_color);
}

.custom-main-jbd .custom-guest_forms-right .custom-left-wave-pattern,
.custom-main-jbd .custom-safari_manifest-right .custom-left-wave-pattern,
.custom-main-jbd .custom-travel_wallet-right .custom-left-wave-pattern,
.custom-main-jbd .custom-relocations-right .custom-left-wave-pattern {
  display: block;
}

.custom-main-jbd .custom-landing,
.custom-main-jbd .custom-right,
.custom-main-jbd .custom-left-img,
.custom-main-jbd .custom-right-headline,
.custom-main-jbd .custom-block,
.custom-main-jbd .custom-right-headline::before,
.custom-main-jbd .custom-guest_forms-right .custom-left-wave-pattern,
.custom-main-jbd .custom-safari_manifest-right .custom-left-wave-pattern,
.custom-main-jbd .custom-travel_wallet-right .custom-left-wave-pattern,
.custom-main-jbd .custom-relocations-right .custom-left-wave-pattern,
.custom-main-jbd .custom-area-right .custom-left-wave-pattern,
.custom-main-jbd .custom-safari_costs-right .custom-left-wave-pattern {
  background-color: var(--jbd_bg-color);
}

.custom-main-jbd .custom-right-headline-inner {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 26px;
  color: var(--jbd-font_accented);
}

.custom-main-jbd .custom-costs-total-info {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--jbd-font_accented);
}

.custom-main-jbd .custom-left-subheadline,
.custom-main-jbd .custom-right-subheadline,
.custom-main-jbd .custom-left-traveldates,
.custom-main-jbd .custom-gp-dashboard-travel-dates,
.custom-main-jbd .custom-gp-dashboard-prepared-for,
.custom-main-jbd .custom-right-headline,
.custom-main-jbd .custom-right-headline-inner,
.custom-main-jbd .custom-left-location,
.custom-main-jbd .custom-overview-item-title,
.custom-main-jbd .custom-right-subheadline-text,
.custom-main-jbd strong.custom-listblock-desc,
.custom-main-jbd .custom-costs-item .custom-costs-total-info,
.custom-main-jbd .custom-costs-item strong.custom-costs-total-info span,
.custom-main-jbd .custom-costs-item .custom-costs-item-title,
.landing-theme-jbd .custom-gp-dashboard-prepared-for,
.landing-theme-jbd .custom-gp-dashboard-travel-dates {
  font-style: normal;
  font-family: 'Euclid Triangle', sans-serif;
}

.custom-main-jbd .custom-safari_costs,
.custom-main-jbd .custom-safari_costs .custom-area-right .custom-left-wave-pattern,
.custom-main-jbd .custom-safari_costs .custom-safari_costs-right .custom-left-wave-pattern,
.custom-main-jbd .custom-safari_costs .custom-left-img,
.custom-main-jbd .custom-safari_costs .custom-right,
.custom-main-jbd .custom-safari_costs .custom-right-headline,
.custom-main-jbd .custom-safari_costs .custom-right-headline::before,
.custom-main-jbd .custom-safari_costs .custom-right-headline-inner,
.custom-main-jbd .custom-safari_costs .custom-block,
.custom-main-jbd .custom-activity-right,
.custom-main-jbd .custom-activity .custom-block,
.custom-main-jbd .custom-activity .custom-right-headline,
.custom-main-jbd .custom-activity .custom-right-headline::before,
.custom-main-jbd .custom-area,
.custom-main-jbd .custom-area .custom-area-right .custom-left-wave-pattern,
.custom-main-jbd .custom-area .custom-left-img,
.custom-main-jbd .custom-area .custom-right,
.custom-main-jbd .custom-area .custom-right-headline,
.custom-main-jbd .custom-area .custom-right-headline::before,
.custom-main-jbd .custom-area .custom-right-headline-inner,
.custom-main-jbd .custom-area .custom-block {
  background-color: #fff;
}

.custom-main-jbd .custom-safari_overview-right,
.custom-main-jbd .custom-safari_overview .custom-block,
.custom-main-jbd .custom-safari_overview .custom-right-headline,
.custom-main-jbd .custom-safari_overview .custom-right-headline::before,
.custom-main-jbd .custom-about_us-right,
.custom-main-jbd .custom-about_us .custom-block,
.custom-main-jbd .custom-about_us .custom-right-headline,
.custom-main-jbd .custom-about_us .custom-right-headline::before {
  background-color: #fff;
}

.custom-main-jbd .custom-safari_map-right,
.custom-main-jbd .custom-safari_map-right .custom-left-img,
.custom-main-jbd .custom-safari_map-right .custom-right,
.custom-main-jbd .custom-safari_map-right .custom-right-headline,
.custom-main-jbd .custom-safari_map-right .custom-right-headline::before,
.custom-main-jbd .custom-safari_map-right .custom-right-headline-inner,
.custom-main-jbd .custom-safari_map-right .custom-block {
  background-color: #edebe6;
}

.custom-main-jbd .custom-left-subheadline {
  margin-left: 0;
  font-size: 23px;
  line-height: 25px;
}

.custom-main-jbd .custom-left-subheadline,
.landing-theme-jbd .custom-gp-dashboard-prepared-for,
.landing-theme-jbd .custom-gp-dashboard-card-descr {
  font-weight: 300;
}

.custom-main-jbd .custom-cover_page-left .custom-left-subheadline {
  text-transform: uppercase;
}

.custom-main-jbd .custom-left-location,
.custom-main-jbd strong.custom-listblock-desc,
.custom-main-jbd .custom-costs-item .custom-costs-total-info,
.custom-main-jbd .custom-costs-item strong.custom-costs-total-info span,
.custom-main-jbd .custom-costs-item .custom-costs-item-title {
  font-size: 18px;
}

.custom-main-jbd .custom-text,
.custom-main-jbd .custom-typography-text,
.custom-main-jbd .custom-overview-item-desc,
.custom-main-jbd .custom-overview-map-list-item,
.custom-main-jbd .custom-overview-map-list-item-count {
  font-size: 22px;
}

.custom-main-jbd .custom-costs-item .custom-costs-item-title {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--jbd-font_accented);
}

.landing-theme-jbd .custom-gp-dashboard-card-title {
  text-transform: uppercase;
  font-family: 'Euclid Triangle', sans-serif;
}

.landing-theme-jbd .custom-gp-dashboard-card-descr {
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'Euclid Triangle', sans-serif;
  color: var(--theme_color);
}

.custom-main-jbd .custom-mobile-app-info-button svg line,
.custom-main-jbd .custom-mobile-app-info-button svg rect {
  stroke: #363638;
}

.custom-main-jbd .custom-mobile-app-info-button svg rect:first-of-type {
  stroke: transparent;
}

.custom-main-jbd .custom-landing-page-mobile-nav > *:last-child {
  margin-right: 0;
  padding-right: 0;
}

.custom-main-jbd .custom-right-subheadline {
  padding-bottom: 6px;
}

.custom-main-jbd .custom-right-subheadline-text {
  text-transform: none;
  font-style: normal;
  font-size: 19px;
  font-family: 'GT Sectra', sans-serif;
  line-height: 27px;
  color: var(--jbd-font_accented);
}

.custom-main-jbd strong.custom-listblock-desc {
  text-transform: uppercase;
  font-weight: 500;
}

/* Header */
.custom-main-jbd .custom-right-header {
  position: relative;
  z-index: 10;
  padding: 0 38px;
  background: #d8d8d8;
  border-color: transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .custom-main-jbd .custom-right-header {
    padding: 0 20px;
  }
}

.custom-main-jbd .custom-right-header-companyname {
  text-transform: uppercase;
  font-weight: 700;
  font-style: normal;
  font-size: 17px;
  font-family: 'Univers', sans-serif;
  color: #fff;
}

.custom-main-jbd .custom-right-header-username {
  font-weight: normal;
  font-style: normal;
  font-size: 1.2rem;
  font-family: 'Adobe Garamond Pro', sans-serif;
  line-height: 1;
}

.custom-main-jbd .custom-right-header-user-menu-list-label {
  font-family: 'Adobe Garamond Pro', sans-serif;
}

.custom-main-jbd .custom-mobile-app-info-button {
  background: transparent;
}

.custom-main-jbd .custom-mobile-app-info-button svg {
  height: 19px;
}

.custom-main-jbd .custom-imagehandler-item {
  color: #ccc;
  background-color: #dadada;
}

.custom-main-jbd .custom-imagehandler-item path {
  fill: #fff;
}

.custom-main-jbd .custom-right-header-icon-share path {
  fill: #363638;
}

.custom-main-jbd .custom-right-header-icon-share svg > *:nth-child(3) {
  stroke: #363638;
}

.custom-main-jbd .custom-right-header-icon-print path {
  fill: #363638;
}

.custom-main-jbd .custom-right-header-icon-phone path {
  stroke: #363638;
}

.custom-main-jbd .custom-scroll-arrow-icon path {
  stroke: #986623;
}

.custom-main-jbd .custom-right-header-icon-invite path {
  stroke: #363638;
}

.custom-main-jbd .custom-right-alert > svg > path {
  stroke: #363638;
}

.custom-main-jbd .custom-right-header-icon-print path:nth-child(2) {
  stroke: #363638;
  stroke-width: 0.3px;
}

.custom-main-jbd .custom-left-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  text-align: center;
  color: #fafafa;
}

.custom-main-jbd .custom-headline {
  text-transform: none !important;
}

.custom-main-jbd .custom-headline .public-DraftStyleDefault-block {
  text-align: center;
}

.custom-main-jbd .custom-headline,
.landing-theme-jbd .custom-gp-dashboard-title {
  margin-bottom: 10px;
  font-weight: 400;
  font-family: 'GT Sectra', sans-serif;
}

.custom-main-jbd .custom-guest-form-item-name-empty {
  opacity: 0.4;
}

.custom-main-jbd .custom-overview-map-list-item {
  margin: 0 auto;
  max-width: 600px;
  text-align: left;
}

.custom-main-jbd .custom-overview-map-list-item-count {
  font-weight: 500;
}

.custom-main-jbd .custom-text,
.custom-main-jbd .custom-overview-map-list,
.custom-main-jbd .custom-overview-item-desc,
.custom-main-jbd .custom-listblock-desc,
.custom-main-jbd .custom-costs-item,
.custom-main-jbd .custom-overview-map-list-title,
.custom-main-jbd .custom-overview-item-title {
  font-style: normal;
  font-size: 18px;
  font-family: 'Adobe Garamond Pro', sans-serif;
  line-height: 27px;
}

.custom-main-jbd .custom-text {
  font-family: 'Adobe Garamond Pro', sans-serif;
}

.custom-main-jbd .custom-overview-map-list-title {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Euclid Triangle', sans-serif;
}

.custom-main-jbd .custom-notification-badge {
  background: var(--branding_color);
}

/* landing page bottom-border */
.custom-main-jbd .custom-right::after {
  background-color: transparent;
}

.custom-main-jbd .custom-left-traveldates,
.custom-main-jbd .custom-left-location {
  display: flex;
  justify-content: center;
  margin-left: 0;
  font-weight: 400;
  font-style: normal;
  font-size: 19px;
  line-height: 21px;
}

.custom-main-jbd .custom-overview-map {
  margin-bottom: 34px;
  background-color: #dadada;
}

.custom-main-jbd .custom-left-subheadline-border {
  display: none !important;
}

.custom-main-jbd .custom-safari_overview-left .custom-left-subheadline,
.custom-main-jbd .custom-safari_overview-left .custom-headline-container {
  display: none;
}

.custom-main-jbd .custom-right-page-header {
  position: relative;
  width: 100%;
}

.custom-main-jbd .custom-landing-wrapper-property {
  position: relative;
  width: 100%;
}

.custom-main-jbd .custom-landing-page-mobile-nav {
  display: flex;
  align-items: stretch;
  padding: 0 38px;
  background-color: #fff;
  border-bottom: none;
  box-shadow: 0 8px 6px -6px rgb(0 0 0 / 30%);
}

.custom-main-jbd .custom-landing-page-mobile-nav div:first-child {
  position: relative;
  padding-left: 0;
  height: 100%;
}

.custom-main-jbd .custom-landing-page-mobile-nav-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 3px;
  min-width: auto;
  height: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 10px;
  font-family: 'Euclid Triangle', sans-serif;
  letter-spacing: 1.5px;
  color: var(--base-font_color);
}

.custom-main-jbd .custom-landing-page-mobile-nav-active-link::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 3px;
  background: #c5b181;
}

.custom-main-jbd .custom-nav-notch {
  display: none;
}

.custom-main-jbd .custom-left-location-wrapper {
  position: relative;
  bottom: auto;
  display: block;
}

.custom-main-jbd .custom-left-location-icon {
  display: none;
}

.custom-main-jbd .custom-left-img::before {
  background: linear-gradient(180deg, rgba(62, 62, 64, 0.6), hsla(0, 0%, 76.9%, 0));
}

.custom-main-jbd .custom-left-img::after {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}

.custom-main-jbd .custom-button-type-secondary {
  color: #fff;
  background: var(--branding_color);
  border-color: var(--branding_color);
}

.custom-main-jbd .custom-button-type-secondary:hover {
  box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.3);
}

.custom-main-jbd .custom-add-new-guest-button:hover {
  color: #a7a7a7;
}

.custom-main-jbd .custom-subtitle-block span[data-text='true'] {
  display: inline-block;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-weight: 700;
  font-style: normal;
  font-size: 19px;
  font-family: 'Adobe Garamond Pro', sans-serif;
  line-height: 20px;
  color: #252321;
  border-bottom: 1.5px solid #cfcfcf;
}

.custom-main-jbd .custom-guest_forms-left,
.custom-main-jbd .custom-safari_manifest-left,
.custom-main-jbd .custom-travel_wallet-left,
.custom-main-jbd .custom-relocations-left {
  width: 100%;
}

.custom-main-jbd .custom-safari_map-right {
  padding-top: 20px;
}

.custom-main-jbd .custom-safari_overview-right {
  padding-top: 80px;
}

.custom-main-jbd .custom-landing-wrapper-guest_forms .custom-right-content,
.custom-main-jbd .custom-landing-wrapper-safari_manifest .custom-right-content,
.custom-main-jbd .custom-landing-wrapper-travel_wallet .custom-right-content,
.custom-main-jbd .custom-landing-wrapper-relocations .custom-right-content {
  margin: 0 auto;
  margin-top: 72px !important;
  width: 100%;
  max-width: 800px;
}

.custom-main-jbd .custom-guest-form-container .custom-head-subtitle,
.custom-main-jbd .custom-guest-form-main-form .custom-head-subtitle {
  text-transform: uppercase;
  font-style: normal;
  font-family: 'Euclid Triangle', sans-serif;
}

.landing-theme-jbd:not(.landing-guest-builder) .custom-modal-content {
  background-color: #eeebe6;
}

.landing-theme-jbd:not(.landing-guest-builder) .custom-modal-title {
  font-family: 'Adobe Garamond Pro', sans-serif;
}

.landing-theme-jbd .custom-guest_forms .custom-button,
.landing-theme-jbd:not(.landing-guest-builder) .custom-modal-content .custom-button {
  font-weight: 700;
  font-size: 15px;
  font-family: 'Adobe Garamond Pro', sans-serif;
}

.landing-theme-jbd .custom-guest_forms .custom-button-type-default,
.landing-theme-jbd:not(.landing-guest-builder) .custom-modal-content .custom-button-type-default {
  background-color: #eeebe6;
}

.landing-theme-jbd .custom-guest_forms .custom-button-type-default:hover,
.landing-theme-jbd:not(.landing-guest-builder) .custom-modal-content .custom-button-type-default:hover {
  color: #a7a7a7;
}

/* ------------------------------- Tables ------------------------------- */
.custom-main-jbd .custom-manifest-table th,
.custom-main-jbd .custom-relocations th {
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Euclid Triangle', sans-serif;
  color: #fff;
  background-color: var(--theme_color);
  border: 1px solid #d0d0d0;
}

.custom-main-jbd .custom-manifest-table td,
.custom-main-jbd .custom-relocations td {
  text-transform: uppercase;
  font-family: 'Euclid Triangle', sans-serif;
  background-color: #f2f2f2;
  border: 1px solid #d0d0d0;
}

.custom-main-jbd .custom-flights-datepicker-wrapper,
.custom-main-jbd .custom-flights-td,
.custom-main-jbd .custom-flights-td .public-DraftEditor-content {
  text-transform: uppercase;
  font-family: 'Euclid Triangle', sans-serif;
}

.custom-main-jbd .custom-flights-td .public-DraftEditor-content {
  font-size: 14px;
}

.custom-main-jbd .custom-relocations tr.active td.tdContent {
  animation: pulse-theme-color 2s ease-in-out;
}

@keyframes pulse-theme-color {
  0% {
    background-color: #f2f2f2;
  }

  25% {
    background-color: var(--theme_color-lighten);
  }

  50% {
    background-color: var(--theme_color);
  }

  75% {
    background-color: var(--theme_color-lighten);
  }

  100% {
    background-color: #f2f2f2;
  }
}

.custom-main-jbd .custom-guest-file-manager-section-title {
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Euclid Triangle', sans-serif;
}

.custom-main-jbd .custom-guest-file-manager-item-name {
  font-weight: 400;
  font-family: 'Euclid Triangle', sans-serif;
}

.custom-main-jbd .custom-guest-file-manager-item-name.custom-typography-link {
  text-transform: uppercase;
  font-weight: 400;
  font-family: 'Euclid Triangle', sans-serif;
  color: var(--theme_color);
}

.custom-main-jbd .custom-accordion-item-head {
  border-bottom: 1px solid var(--theme_color);
}

.custom-main-jbd .custom-accordion-item-title {
  padding-bottom: 10px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.9rem;
  font-family: 'Euclid Triangle', sans-serif;
  line-height: 23px;
  color: var(--theme_color);
}

.custom-main-jbd .custom-accordion-item-content {
  padding-top: 10px;
}

/* ------------------------------- END Tables ------------------------------- */

/* -------------------------- TABLET; DESKTOP ------------------------------- */
@media (min-width: 769px) {
  .custom-main-jbd .custom-left-content {
    padding-left: 50px;
  }

  .custom-main-jbd .custom-right-headline::before {
    height: 0;
  }

  .custom-main-jbd .custom-landing-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    background: #fff;
  }

  .custom-main-jbd .custom-landing {
    width: 50%;
    min-height: var(--leftSideHeight);
  }

  .custom-main-jbd .custom-cover_page-left,
  .custom-main-jbd .custom-left[data-index='0'].custom-guest_forms-left {
    position: relative;
    top: 0 !important;
    height: calc(var(--leftSideHeight) - 50px);
  }

  .custom-main-jbd .custom-cover_page-left::after,
  .custom-main-jbd .custom-left[data-index='0'].custom-guest_forms-left::after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: 50%;
    z-index: 5;
    margin-left: -1px;
    width: 1px;
    height: 100px;
    background-color: #986623;
  }

  .custom-main-jbd .custom-landing-wrapper-safari_map .custom-overview-map,
  .custom-main-jbd .custom-landing-wrapper-safari_map .mapContainerClassName {
    height: 600px !important;
  }

  .custom-main-jbd .custom-safari_map-left,
  .custom-main-jbd .custom-cover_page {
    display: none !important;
  }

  .custom-main-jbd .custom-cover_page-left,
  .custom-main-jbd .custom-property-left,
  .custom-main-jbd .custom-landing-wrapper-safari_map,
  .custom-main-jbd .custom-landing-wrapper-safari_costs,
  .custom-main-jbd .custom-safari_costs-left,
  .custom-main-jbd .custom-landing-wrapper-area,
  .custom-main-jbd .custom-area-left {
    width: 100%;
  }

  .custom-main-jbd .custom-landing-wrapper-area .custom-right-content,
  .custom-main-jbd .custom-landing-wrapper-safari_costs .custom-right-content,
  .custom-main-jbd .custom-property-right .custom-right-content {
    margin: 0 auto;
    margin-top: 72px !important;
    max-width: 800px;
  }

  .custom-main-jbd {
    --leftSideHeight: calc(100vh - var(--jbd_header-height)) !important;
  }

  .custom-main-jbd.custom-main-preview {
    --leftSideHeight: calc(var(--modalHeight) - var(--jbd_header-height)) !important;
  }

  .custom-main-guest-domain.custom-main-jbd {
    --leftSideHeight: calc(100vh - var(--jbd_header-height) - var(--gpNavHeaderHeight)) !important;
  }

  .custom-section-jbd .custom-main-jbd {
    /* top header 65px - buttons container  25px - bottom margin 66px - landing sticky header 94px */
    --leftSideHeight: calc(100vh - 65px - 25px - 66px - var(--jbd_header-height)) !important;
  }

  .override .custom-section-jbd .custom-main-jbd {
    /* top header 65px -buttons container  25px - bottom margin 66px - landing sticky header 94px - override header 65px */
    --leftSideHeight: calc(100vh - 65px - 25px - 66px - var(--jbd_header-height) - 65px) !important;
  }

  .custom-section-jbd .custom-main-jbd.withActions {
    /* top header 65px -buttons container  69px - bottom margin 66px - landing sticky header 94px */
    --leftSideHeight: calc(100vh - 65px - 69px - 66px - var(--jbd_header-height)) !important;
  }

  .override .custom-section-jbd .custom-main-jbd.withActions {
    /* top header 65px -buttons container  69px - bottom margin 66px - landing sticky header 94px - override header 65px */
    --leftSideHeight: calc(100vh - 65px - 69px - 66px - var(--jbd_header-height) - 65px) !important;
  }

  .custom-main-jbd .custom-left {
    top: var(--jbd_header-height); /* landing sticky header 94px */
  }

  .custom-main-jbd .custom-area-right .custom-left-wave-pattern,
  .custom-main-jbd .custom-safari_costs-right .custom-left-wave-pattern {
    display: block;
  }
}

/* -------------------------- MOBILE ------------------------------- */
@media (max-width: 768px) {
  .custom-main-jbd .custom-right-header-companyname {
    font-size: 22px;
  }

  .custom-main-jbd .custom-safari_map-left,
  .custom-main-jbd .custom-cover_page-right,
  .custom-main-jbd .custom-safari_overview-left {
    display: none !important;
  }

  .custom-main-jbd .custom-cover_page {
    padding-bottom: 0;
  }

  .custom-main-jbd .custom-headline {
    padding-bottom: 0;
  }

  .custom-main-jbd .custom-left-content {
    padding: 20px;
  }

  .custom-main-jbd ~ div .custom-mobile-app-info {
    font-family: 'Euclid Triangle', sans-serif;
  }
}

/* ------------------------ BUTTONS ---------------------------- */
.landing-theme-jbd .custom-gp-dashboard-card-btn {
  color: white;
  background: transparent;
  border: 1px solid white;
  border-radius: 0;
}

.landing-theme-jbd .custom-gp-dashboard-card-btn:hover {
  color: black;
  background: white;
}
