.radioWrapper {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0 2.5rem 0 3rem;
  min-height: 2rem;
}

.radioWrapper.primary {
  margin: 0 0.6rem;
  padding: 0.4rem 1rem;
  border: 1px solid var(--input_border_color);
  border-radius: 99px;
  box-shadow: 0 1px 0 0 #0000000d;
  transition: all 0.3s;
}

.radioWrapper.primary:hover,
.radioWrapper.primary.radioWrapper-active {
  background-color: #eee;
}

.radio {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  border: 2px solid var(--input_border_color);
  border-radius: 50%;
  transition: all 0.3s;
}

.radio::after {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  transition: all 0.35s;
}

.radioTitle {
  position: relative;
  font-size: 1.7rem;
  font-family: var(--font_family-accent);
  line-height: 2rem;
  color: var(--text_color);
  transition: all 0.35s;
}

.hiddenBaseRadionButton {
  position: absolute;
  -webkit-appearance: none;
  appearance: none;
}

.radioWrapper:hover .radio {
  border-color: #d9d9d9;
}

.radioWrapper:hover .radio::after {
  background: var(--input-secondary-lighten);
}

.hiddenBaseRadionButton:checked + .radio {
  border-color: var(--input_border_color-focus);
}

.hiddenBaseRadionButton:checked + .radio::after {
  background: var(--input_border_color-focus);
}

/* is active */

.radio-active::after {
  background: var(--input_border_color-focus);
}

/* is error */

/* disabled */
.radioWrapper-disabled .radioTitle {
  color: var(--input-secondary);
}

.radioWrapper-active .radio-disabled::after {
  background: var(--input-secondary-lighten);
}

.radioWrapper-disabled:hover .radio::after {
  background: none;
}

.radioWrapper-disabled:hover .radio-active::after {
  background: var(--input-secondary-lighten);
}

/* solid */
.radioWrapper-solid {
  display: flex;
  padding: 0;
}

.radioWrapper-solid .radioTitleWrapper {
  display: flex;
  margin-left: -1px;
  padding: 1.25rem 2rem;
  border: 1px solid var(--input-secondary-lighten);
  transition: all 0.35s;
}

.radioWrapper-solid .radioTitleWrapper-first {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.radioWrapper-solid .radioTitleWrapper-last {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.hiddenBaseRadionButton:checked + .radioTitleWrapper .radioTitle {
  color: var(--input_border_color-focus);
}

.hiddenBaseRadionButton:checked + .radioTitleWrapper {
  z-index: 2;
  border-color: var(--input_border_color-focus);
}

/* no margin for Field inside form groups */
.radioWrapper-noMargin {
  margin-bottom: 0;
}
