.radioGroup {
  display: flex;
}

.radioGroup-vertical {
  flex-direction: column;
}

/* solid group */
.radioGroup-solid {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.radioGroup-solid .radioGroup-item {
  margin: 0;
}
